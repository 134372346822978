import React from 'react';
import {
  Facepile,
  Icon,
  IDropdownOption,
  IFacepilePersona,
  Persona,
  PersonaSize,
  DocumentCardActivity,
  IDocumentCardActivityPerson,
} from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import i18next, { t } from 'i18next';

export const CollaboratorGridList = (props: EntityGridListProps) => {
  const formatEquipo = (item: any) => {
    let equipo = (item.col_nombre + ' ' + item.col_apellido).split(' | ');

    var getInitials = function () {
      let initials = item.col_iniciales;
      return initials;
    };
    let equipoPersonas: IFacepilePersona[] = equipo.map((persona: string) => {
      return {
        personaName: persona,
        imageInitials: getInitials(),
      };
    });

    return (
        <Facepile
            personas={equipoPersonas}
            personaSize={10}
            maxDisplayablePersonas={6}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            overflowButtonType={1}
            onRenderPersona={(props) => (
                <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
            )}
        />
    );
  };

  const columns = [
    {
      fieldName: 'col_iniciales',
      name: '',
      _onRender: (item: any) => formatEquipo(item),
      onRenderPersona: () => <></>,
      maxWidth: 40,
    },
    {
      fieldName: 'col_nombre',
      name: t('Nombre'),
    },
    {
      fieldName: 'col_apellido',
      name: t('Apellido'),
    },
    {
      fieldName: 'col_email',
      name: t('Mail'),
    },
    {
      fieldName: 'sector',
      name: t('Sector'),
    },
    {
      fieldName: 'cargo_nombre',
      name: t('Cargo'),
    },
  ];
  return (
      <>
        <DetailsListView
            keyList={'colaboradores'}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Colaboradores')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            changeBlockUI={props.changeBlockUI}
            courier={props.courier}
        ></DetailsListView>
      </>
  );
};
