import LazyInitialization from '../../lib/LazyInitialization';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { SystemDateParser } from '../SystemDateParser';
import OrganizationChartSystem from '../organization_chart/OrganizationChartSystem';
import { Collaborator } from './Collaborator';
import { CollaboratorPosition } from './CollaboratorPosition';
import { CollaboratorPositionHelper } from './CollaboratorPositionHelper';
import { CompanyPosition } from './CompanyPosition';
import { ProvidedCollaboratorPosition } from './ProvidedCollaboratorPosition';

export interface ICompanyPosition {
  cargo_id: string;
  cargo_nombre: string;
  cargo_activo: boolean;
}

export interface ICollaboratorPosition {
  cargo: ICompanyPosition | null;
  carcol_colid: string;
  carcol_id: string;
  carcol_cargoid: string;
  carcol_fecha_desde: string; // '14/05/2021'
  carcol_fecha_hasta: string | null; // '14/05/2021'
  carcol_activo: boolean;
  carcol_norid: string;
}

export interface ICollaboratorBase {
  col_id: string;
  col_nombre: string;
  col_apellido: string;
  col_email: string;
  col_imagen_url: string | null;
  col_iniciales: string;
  col_activo: boolean;
  // col_telefono_movil: null;
  col_fecha_nacimiento: string;
  col_id_sap: string;
  // col_documento_numero: null;
  // col_tipo_documento: null;
}

export interface ICollaborator extends ICollaboratorBase {
  cargoColaborador: ICollaboratorPosition | null;
}

export interface ISaveCollaborator {
  id: string;
  name: string | undefined;
  lastName: string | undefined;
  birthDay: Date;
  cargo: string;
  active: boolean;
}

export class CollaboratorSystem {
  private readonly entityBase = '/colaboradores';
  private readonly collaboratorPositionsEntityBase = '/cargos-colaboradores';
  private readonly positionsEntityBase = '/cargos-colaboradores';
  private readonly companyPositionsEntityBase = '/cargos';

  private collaboratorCache: { id: string; value: LazyInitialization<Collaborator> }[];

  constructor(
      private requestHandler: XhrRequestHandler,
      private dateParser: SystemDateParser,
      private organizationChartSystem: OrganizationChartSystem,
      private keyLocalStorageCollaboratorList: string // private collaboratorLicenseSystem: CollaboratorLicenseSystem
  ) {
    this.collaboratorCache = [];
  }

  getDateParser() {
    return this.dateParser;
  }

  getOrganizationChartSystem(): OrganizationChartSystem {
    return this.organizationChartSystem;
  }

  // getCollaboratorLicenseSystem(): CollaboratorLicenseSystem {
  //   return this.collaboratorLicenseSystem;
  // }

  async getCollaborators(): Promise<Collaborator[]> {
    const data = await this.requestHandler.get<ICollaborator[]>(this.entityBase);

    return data.map((col) => Collaborator.fromICollaborator(this, col));
  }

  async getCollaboratorIdentifiedBy(id: string): Promise<Collaborator> {
    const data = await this.requestHandler.get<ICollaborator>(`${this.entityBase}/${id}`);
    return Collaborator.fromICollaborator(this, data);
  }

  async save(collaborator: Collaborator, collaboratorPosition?: CollaboratorPositionHelper) {
    const data = collaborator.toICollaborator();
    if (collaboratorPosition) {
      data.cargoColaborador = collaboratorPosition.toICollaboratorPosition();
    }
    const savedCollaboratorResult = await this.requestHandler.post<ICollaborator, ICollaborator>(
        this.entityBase,
        data
    );
    return Collaborator.fromICollaborator(this, savedCollaboratorResult);
  }

  async getCollaboratorPositionIdentifiedBy(collaboratorId: string): Promise<CollaboratorPosition> {
    const data = await this.requestHandler.get<ICollaboratorPosition>(
        `${this.collaboratorPositionsEntityBase}/current/${collaboratorId}`
    );
    return ProvidedCollaboratorPosition.fromICollaboratorPosition(this, data);
  }

  async getCompanyPositionIdentifiedBy(id: string): Promise<CompanyPosition> {
    const data = await this.requestHandler.get<ICompanyPosition>(`${this.positionsEntityBase}/${id}`);

    return CompanyPosition.fromICompanyPosition(this, data);
  }

  getCollaboratorIdentifiedByLazy(id: string) {
    let cached = this.collaboratorCache.find((cached) => cached.id === id);
    if (!cached) {
      const lazy = new LazyInitialization(() => this.getCollaboratorIdentifiedBy(id));
      cached = { id: id, value: lazy };
      this.collaboratorCache.push(cached);
    }

    return cached.value;
  }

  async getCompanyPositionList() {
    const data = await this.requestHandler.get<ICompanyPosition[]>(`${this.companyPositionsEntityBase}`);
    return data.map((cp: ICompanyPosition) => CompanyPosition.fromICompanyPosition(this, cp));
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.entityBase}/datagrid`);
  }
}

export default CollaboratorSystem;
