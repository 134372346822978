import * as React from 'react';
import { Announced } from '@fluentui/react/lib/Announced';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  IDetailsHeaderProps,
} from '@fluentui/react/lib/DetailsList';
import { Stack, Sticky, StickyPositionType, Text } from '@fluentui/react';
import axios from 'axios';
import { getDatagridUrlParams } from '../../../common/list-utils';
import './DetailsListView.css';
import FilterWrapperForList from './FilterWrapperForList';
import { getFiltersConfig } from '../list-entities/ListColumnFilter';
import { buttonStylesFor } from '../../../styles/button-styles';
import { themeLight } from '../../../styles/colors';
import { DetailListHeader } from './DetailListHeader';
import { DetailListPagination } from './DetailListPagination';
import { DetailListStatus } from './DetailListStatus';
import { IExampleItem } from '@fluentui/example-data/lib/listItems';
import { DetailsRow, IDetailsListProps } from '@fluentui/react/lib/DetailsList';
import TimeTaxSupportSystemClient from '../../../system';
import i18next, { t } from 'i18next';
import MessageCourier from '../../../lib/MessageCourier';

export interface EntityGridListProps {
  keyList: string;
  onClickCardMode: () => void;
  onClickGridMode: () => void;
  mode: string;
  onItemClick: (item: any) => void;
  quickFilters: any;
  baseUrl: string;
  shouldUpdate?: boolean;
  system?: TimeTaxSupportSystemClient;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
}

interface DetailsListProps {
  cols?: any[];
  keyList: string;
  quickFilters?: any[];
  baseUrl: string;
  dispatchFilter?: (filter: any) => void;
  dispatchPage?: (page: number) => void;
  dispatchSort?: (order: any) => void;
  listFilters: any[];
  isGridList: boolean;
  mapCard?: (item: any, index: number) => any;
  onClickCardMode: () => void;
  onClickGridMode: () => void;
  mode: string;
  title: string;
  onItemClick?: (item: any) => void;
  onItemDoubleClick?: (item: any) => void;
  _renderItemColumn?: (item: IExampleItem, index: number, column: IColumn) => JSX.Element;
  shouldUpdate?: boolean;
  changeBlockUI: (state: boolean) => void;
  courier: MessageCourier;
  classNameDetailsList?: string;
}

export interface IDatagrid<T> {
  count: number;
  data: T[];
  recordsFiltered: number;
  recordsTotal: number;
}

export interface IFilters {
  [index: string]: string;
}
export interface IDetailsListViewState {
  columns: IColumn[];
  items: any[];
  announcedMessage?: string;
  sortList: any;
  perPage: number;
  page: number;
  totalRows: number;
  filters: IFilters;
  shouldUpdate: boolean;
  firstCharge: boolean;
  filtersConfig: any[];
  timeoutId: NodeJS.Timeout | undefined;
}

export class DetailsListView extends React.Component<DetailsListProps, IDetailsListViewState> {
  private _allItems: any[];

  private generateColumns = (_cols: any[]) => {
    let ret: IColumn[] = [];

    _cols.forEach((col, idx: number) => {
      col.key = `${col.fieldName}`;
      col.ariaLabel = col.name;
      col.minWidth = col.minWidth ? col.minWidth : 60;
      col.maxWidth = col.maxWidth ? col.maxWidth : 150;
      col.isResizable = true;
      col.isPadded = true;
      col.onRender = (item: any) => {
        if (col._onRender) {
          return col._onRender(item);
        } else {
          return <span title={item[col.fieldName]}>{item[col.fieldName]}</span>;
        }
      };
      col.onColumnClick = this._onColumnClick;
      ret.push(col);
    });

    return ret;
  };

  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  private fetchData = async (baseUrl: string, firstPage = false) => {
    this.props.changeBlockUI(true);
    try {
      let _page = firstPage ? 1 : this.state.page;

      let listStatus = DetailListStatus.getDetailListStatus(this.props.keyList);
      let params = getDatagridUrlParams(
          listStatus && listStatus.page ? listStatus.page : _page,
          this.state.perPage,
          listStatus && listStatus.filters ? listStatus.filters : this.state.filters,
          this.state.filtersConfig,
          listStatus && listStatus.sortList ? listStatus.sortList : this.state.sortList
      );

      const url = `${baseUrl}${params}`;
      // Obtener el TimeZone del usuario
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await axios.get<IDatagrid<any>>(url, { withCredentials: true, headers: {
          "Time-Zone": timeZone
        }});

      let _state = { ...this.state };
      this._allItems = response.data.data;
      _state.items = response.data.data;
      _state.totalRows = response.data.recordsTotal;

      if (_state.firstCharge) _state.filters = listStatus.filters;
      _state.firstCharge = false;
      //

      this.setState(_state);
    } catch (error) {
      this.props.courier.messageError(error);
    } finally {
      this.props.changeBlockUI(false);
    }
    // return response;
  };

  constructor(props: DetailsListProps) {
    super(props);
    this._allItems = [];
    let columns: IColumn[] = this.props.cols ? this.generateColumns(this.props.cols) : [];
    this.state = {
      items: [],
      columns: columns,
      announcedMessage: undefined,
      sortList: {},
      perPage: sessionStorage.getItem('perPage') != null ? Number(sessionStorage.getItem('perPage')) : 25,
      page: 1,
      totalRows: 0,
      filters: {},
      shouldUpdate: false,
      firstCharge: true,
      filtersConfig: getFiltersConfig(this.props.quickFilters),
      timeoutId: undefined,
    };
    this.fetchData = this.fetchData.bind(this);
    this.onChangeAnyFilter = this.onChangeAnyFilter.bind(this);
    this.changePerPage = this.changePerPage.bind(this);
  }

  filtersConfig: any = {};

  updateConditions(prevProps: DetailsListProps, prevState: IDetailsListViewState) {
    return (
        prevState.shouldUpdate !== this.state.shouldUpdate ||
        this.props.keyList !== prevProps.keyList ||
        this.props.shouldUpdate !== prevProps.shouldUpdate
    );
  }

  componentDidMount(): void {
    // Se encarga de cargar los filtros por defecto y guardarlos dentro del local storage.
    let listStatus = DetailListStatus.getDetailListStatus(this.props.keyList);
    let _page = listStatus.page ? listStatus.page : 1;

    if (this.isEmpty(listStatus.filters)) {
      let _defaultFilters: any = {};
      this.props.quickFilters?.forEach((quickFilter) => {
        if (quickFilter.defaultValue) {
          _defaultFilters[quickFilter.name] = quickFilter.defaultValue;
        }
      });
      DetailListStatus.setDetailListStatus(this.props.keyList, _page, _defaultFilters, this.state.sortList);
    }
    listStatus = DetailListStatus.getDetailListStatus(this.props.keyList);
    this.setState({
      ...this.state,
      filters: listStatus.filters,
      page: listStatus.page,
      sortList: listStatus.sortList,
    });
    this.fetchData(this.props.baseUrl, true);
  }

  componentDidUpdate(prevProps: DetailsListProps, prevState: IDetailsListViewState) {
    if (this.updateConditions(prevProps, prevState)) {
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId);
      }

      this.setState({
        ...this.state,
        timeoutId: setTimeout(async () => {
          this.fetchData(this.props.baseUrl, true);
        }, 1000),
      });
    } else if (prevProps.quickFilters !== this.props.quickFilters) {
      this.setState({ filtersConfig: getFiltersConfig(this.props.quickFilters) });
    }
  }

  private onChangeAnyFilter(
      _filters: { [index: string]: string },
      shouldUpdate: boolean,
      cleanFilters?: boolean
  ) {
    let listStatus = DetailListStatus.getDetailListStatus(this.props.keyList);
    if (cleanFilters) {
      DetailListStatus.setDetailListStatus(this.props.keyList, 1, _filters, {});
      this.setState({
        ...this.state,
        sortList: {},
        filters: listStatus.filters,
        shouldUpdate: shouldUpdate,
        firstCharge: true,
      });
      if (this.props.dispatchSort) this.props.dispatchSort({ order: {} });
    } else {
      this.setState({ ...this.state, shouldUpdate: shouldUpdate, filters: _filters });
      listStatus.filters = _filters;
      listStatus.page = 0;
      DetailListStatus.setDetailListStatus(
          this.props.keyList,
          listStatus.page,
          _filters,
          this.state.sortList
      );
    }

    if (this.props.dispatchFilter) this.props.dispatchFilter(_filters);

    if (!this.state.firstCharge) {
      if (this.props.dispatchPage) this.props.dispatchPage(1);
    }
  }

  private getFiltersAppliedForChips = () => {
    if (this.state.filters) {
      let _filtersActive = Object.keys(this.state.filters);
      let ret = _filtersActive.map((filterActive) => {
        let filterConfig = this.state.filtersConfig.find((_fc) => _fc.name === filterActive);
        if (filterConfig && this.state.filters[filterActive]) {
          return {
            key: filterActive,
            filterConfig: filterConfig,
            value: this.state.filters[filterActive],
          };
        }
      });
      return ret;
    }
    return [];
  };

  onClickChipFilter = (filterApplied: any) => {
    if (filterApplied && !filterApplied.filterConfig.disabled) {
      let _state = { ...this.state };
      delete _state.filters[filterApplied.key];
      this.onChangeAnyFilter(_state.filters, filterApplied.key);
    }
  };

  _getClassNameForFilter = () => 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl2 ms-xxl2 filter-menu';
  _getClassNameForBody = () =>
      this.state.filtersConfig.length > 0
          ? 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl10 ms-xxl10'
          : 'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12';
  defaultCommandBarButtonStyles = buttonStylesFor({
    backgroundColor: themeLight.backgroundSecondary,
    backgroundColorFocused: themeLight.backgroundSecondaryFocused,
  });

  private _onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    if (props) {
      return (
          <div
              onClick={() => {
                if (this.props.onItemClick) this.props.onItemClick(props.item);
              }}
          >
            <DetailsRow
                {...props}
                styles={{
                  root: {
                    backgroundColor: props.itemIndex % 2 === 0 ? '#f5f5f5' : 'transparent',
                    selectors: {
                      ':hover': { backgroundColor: '#d9d8d8' },
                    },
                  },
                }}
            />
          </div>
      );
    }
    return null;
  };

  private getClassNameDetailList = () => {
    if (this.props.classNameDetailsList) {
      return this.props.classNameDetailsList + ' overflow-x-hidden';
    }
    return 'detailview-height overflow-x-hidden';
  };

  public render() {
    const { columns, items, announcedMessage } = this.state;

    return (
        <>
          <div className="padding-list">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row row-flex-calendar">
                {this.state.filtersConfig.length > 0 && (
                    <div className={this._getClassNameForFilter()}>
                      <FilterWrapperForList
                          initFilters={this.props.listFilters ? this.props.listFilters : []}
                          filtersConfigList={this.state.filtersConfig}
                          onChangeAnyFilter={this.onChangeAnyFilter}
                          shouldUpdate={this.state.shouldUpdate}
                          filters={this.state.filters}
                      ></FilterWrapperForList>
                    </div>
                )}
                {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
                <div className={this._getClassNameForBody()}>
                  <DetailListHeader
                      onClickChipFilter={this.onClickChipFilter}
                      filtersApplied={this.getFiltersAppliedForChips()}
                      onClickCardMode={() => this.props.onClickCardMode()}
                      onClickGridMode={() => this.props.onClickGridMode()}
                      mode={this.props.mode}
                      title={this.props.title}
                  />

                  <>
                    {this.props.isGridList && items && items.length > 0 ? (
                        <DetailsList
                            className={this.getClassNameDetailList()}
                            items={items}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            getKey={this._getKey}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            onRenderRow={this._onRenderRow}
                        />
                    ) : (
                        <div className="ms-Grid grid-card-list" dir="ltr">
                          <div className="ms-Grid-row">
                            <>{this.props.mapCard ? <div>{items.map(this.props.mapCard)}</div> : null}</>
                          </div>
                        </div>
                    )}
                  </>
                  {items && items.length === 0 && (
                      <div className="mt-5 text-center">
                        <Text variant="large">{t('No se encontraron resultados')}</Text>
                      </div>
                  )}

                  {items && items.length > 0 && (
                      <Stack>
                        <DetailListPagination
                            page={this.state.page}
                            perPage={this.state.perPage}
                            totalRows={this.state.totalRows}
                            onChangePage={this.changePage}
                            onChangePerPage={this.changePerPage}
                        ></DetailListPagination>
                      </Stack>
                  )}
                </div>
              </div>
              {/* #################################### No borrar buena para debug */}
              {/* <small>{this.props.keyList}</small>
            <small>{JSON.stringify(this.state.filters)}</small>
            <small>{JSON.stringify(DetailListStatus.getDetailListStatus(this.props.keyList).filters)}</small> */}
            </div>
          </div>
        </>
    );
  }

  private changePage = (action: string) => {
    let _state = { ...this.state };

    if (action === 'next') {
      if (_state.page * _state.perPage <= _state.totalRows) _state.page = _state.page + 1;
    }

    if (action === 'back') {
      if (_state.page * _state.perPage >= _state.perPage) _state.page = _state.page - 1;
    }

    if (action === 'toLast') {
      _state.page = Math.ceil(this.state.totalRows / this.state.perPage);
    }

    if (action === 'toFirst') {
      _state.page = 1;
    }
    let listStatus = DetailListStatus.getDetailListStatus(this.props.keyList);
    DetailListStatus.setDetailListStatus(
        this.props.keyList,
        _state.page,
        listStatus.filters,
        this.state.sortList
    );

    this.setState(_state, () => {
      this.fetchData(this.props.baseUrl);
    });
  };

  private changePerPage = (event: React.FormEvent<HTMLDivElement>, item: any) => {
    this.changePage('toFirst');
    let _state = { ...this.state };
    _state.perPage = item.key;
    sessionStorage.setItem('perPage', item.key);

    _state.page = 1;

    this.setState(_state);
  };

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private onItemDoubleClick(item: any): void {
    if (this.props.onItemDoubleClick) {
      this.props.onItemDoubleClick(item);
    } else if (this.props.onItemClick) {
      this.props.onItemClick(item);
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    let _state = { ...this.state };

    let idx = -1;
    _state.columns.forEach((col, _idx) => {
      col.isSorted = false;

      if (col.fieldName === column.fieldName) idx = _idx;
    });

    if (column.fieldName) {
      let _preOrder = _state.sortList[column.fieldName];
      _state.sortList = {};
      if (_preOrder) {
        if (_preOrder === -1) {
          _state.sortList[column.fieldName] = 1;
          _state.columns[idx].isSorted = true;
          _state.columns[idx].isSortedDescending = false;
        } else if (_preOrder === 1) {
          _state.sortList[column.fieldName] = -1;
          _state.columns[idx].isSorted = true;
          _state.columns[idx].isSortedDescending = true;
        }
      } else {
        _state.sortList[column.fieldName] = -1;
        _state.columns[idx].isSorted = true;
        _state.columns[idx].isSortedDescending = true;
      }
    }

    _state.page = 1;

    if (!this.state.firstCharge) {
      DetailListStatus.setDetailListStatus(this.props.keyList, _state.page, _state.filters, _state.sortList);
    }

    this.setState(_state, () => {
      this.fetchData(this.props.baseUrl);
    });
  };
}
