import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Facepile,
  IPersonaSharedProps,
  IFacepilePersona,
  Persona,
  PersonaSize,
  DefaultButton,
  IIconProps,
  ComboBox,
  IComboBoxOption,
  Label,
} from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { DefaultPalette } from '@fluentui/react';
import { Toggle, TextField, DatePicker } from '@fluentui/react';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import {
  buttonStylesForProjects,
  defaultCommandBarButtonStyles,
  titleCommandBarButtonStyles,
} from '../../../styles/button-styles';
// import {Collaborator} from '../../../system/collaborators/Collaborator'
import './CollaboratorView.css';
import { DateCustom } from '../../../lib/DateCustom';
import CollaboratorPanels from './CollaboratorPanels';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import { CollaboratorPositionHelper } from '../../../system/collaborators/CollaboratorPositionHelper';
import { CompanyPosition } from '../../../system/collaborators/CompanyPosition';
import { routes } from '../../../common/constants';
import { useLocation } from 'react-router-dom';
import { ProvidedCollaboratorPosition } from '../../../system/collaborators/ProvidedCollaboratorPosition';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { OrganizationChartDropdown } from '../../Common/OrganizationChartDropdown';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { useTranslation, Trans } from 'react-i18next';
import * as languaje from '../../../i18n.js';
import { RefreshPageDialog } from '../../Common/RefreshPageDialog';
import { User } from '../../../system/User';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';

interface ICollaboratorViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (change: boolean) => void;
  user: User;
}

const CollaboratorView: React.FC<ICollaboratorViewProps> = ({ system, ...props }) => {
  const { t, i18n } = useTranslation();
  let { collaboratorId } = useParams();
  let id = collaboratorId;
  const navigate = useNavigate();
  const defaultDate = new Date('01/01/1950');
  const search = useLocation().search;

  const [selectedCollaborator, setSelectedCollaborator] = React.useState<Collaborator>();
  const [selectedCollaboratorOrganizationChartNode, setSelectedCollaboratorOrganizationChartNode] =
      React.useState<OrganizationChartNode>();
  const [selectedCollaboratorCargo, setSelectedCollaboratorCargo] = React.useState<string>();
  const [selectedCollaboratorChart, setSelectedCollaboratorChart] = React.useState<string>();
  const [selectedCollaboratorBirthday, setSelectedCollaboratorBirthday] = React.useState<Date>(new Date());
  const [collaboratorPositions, setCollaboratorPositions] = React.useState<CompanyPosition[]>();
  const [canEdit, setCanEdit] = React.useState(false);
  const dropdownData = useSystemCall(system, (system) => system.getProjectSystem().getFiltersData());
  const [showCollabPanel, setShowCollabPanel] = React.useState(false);
  const [selectedCollaboratorPosition, setSelectedCollaboratorPosition] =
      React.useState<CollaboratorPositionHelper>();
  const [positionName, setPositionName] = React.useState<string>();
  const [nodeChartName, setNodeChartName] = React.useState<string>();
  const [showRefreshPageDialog, setShowRefreshPageDialog] = React.useState<boolean>(false);

  const nodes = useAsyncDataCourier(
      () => system.getOrganizationChartSystem().getAllNodes(),
      [system],
      props.courier
  );
  const lastLevel = useAsyncDataCourier(
      () => system.getOrganizationChartSystem().getLastLevel(),
      [system],
      props.courier
  );

  const showPanel = new URLSearchParams(search).get('showPanel');
  const from = new URLSearchParams(search).get('from');
  const to = new URLSearchParams(search).get('to');

  React.useEffect(() => {
    if (showPanel) {
      setShowCollabPanel(true);
    }
  }, [showPanel]);

  const getCollaborator = async () => {
    if (id) {
      let collaboratorFromAPI: any = await system.getCollaboratorSystem().getCollaboratorIdentifiedBy(id);
      setSelectedCollaborator(collaboratorFromAPI);
    }
  };

  const isMyProfile = () => {
    return id === props.user.getCollaboratorId();
  };

  const getCollaboratorArea = async () => {
    if (selectedCollaborator) {
      let collaboratorPosition: ProvidedCollaboratorPosition =
          (await selectedCollaborator.getPosition()) as ProvidedCollaboratorPosition;
      let collaboratorNode = await collaboratorPosition.getOrganizationNode();
      let collaboratorCargo = await selectedCollaborator.getPositionName();
      let collaboratorBirthday = selectedCollaborator.getBirthday();
      let collaboratorPositions = await system.getCollaboratorSystem().getCompanyPositionList();
      let birthday = new Date();
      if (collaboratorBirthday.length <= 10) {
        var dateString = collaboratorBirthday;
        var dateParts = dateString.split('/');
        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(+dateParts[2], (dateParts[1] as any) - 1, +dateParts[0]);
        birthday = dateObject;
      }
      let organizationNode = await collaboratorPosition.getOrganizationNode();
      setSelectedCollaboratorChart(organizationNode.getId());
      setSelectedCollaboratorOrganizationChartNode(collaboratorNode);
      setSelectedCollaboratorCargo(collaboratorCargo);
      setSelectedCollaboratorBirthday(birthday ? birthday : new Date(defaultDate));
      setCollaboratorPositions(collaboratorPositions);
      setSelectedCollaboratorPosition(CollaboratorPositionHelper.fromProvided(collaboratorPosition));
    }
  };

  React.useEffect(() => {
    getCollaborator();
  }, [id]);

  React.useEffect(() => {
    getCollaboratorArea();
  }, [selectedCollaborator]);

  const onSaveCollaborator = async () => {
    if (selectedCollaborator && selectedCollaboratorPosition && selectedCollaboratorPosition.validateId()) {
      try {
        await system.getCollaboratorSystem().save(selectedCollaborator, selectedCollaboratorPosition);
        props.courier.messageSuccess(t('Se ha guardado exitosamente'));
        setCanEdit(false);
        // setShouldRefreshDefenseFile(!shouldRefreshDefenseFile);
      } catch (error) {
        props.courier.messageError(error);
      }
    } else {
      props.courier.messageError(t('Todos los datos deben estar completos'));
    }
  };

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Colaboradores'),
          iconProps: {
            iconName: 'ReminderPerson',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          iconProps: {
            iconName: 'Back',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
          onClick: () => {
            window.history.back();
          },
        },
        {
          key: 'modificar',
          text: canEdit ? t('Cancelar') : t('Modificar'),
          iconProps: { iconName: 'Edit', color: DefaultPalette.themeLighterAlt },
          buttonStyles: titleCommandBarButtonStyles,
          onClick: () => setCanEdit(!canEdit),
          disabled: !isMyProfile() && !props.user.getProfile().isAdminUser(),
        },
        {
          key: 'save',
          text: t('Guardar'),
          iconProps: { iconName: 'Save', color: DefaultPalette.themeLighterAlt },
          buttonStyles: canEdit ? buttonStylesForProjects : titleCommandBarButtonStyles,
          onClick: () => {
            onSaveCollaborator();
          },
          disabled: !canEdit,
        },
      ],
      [onSaveCollaborator, canEdit]
  );

  const formatEquipo = (item: any) => {
    let equipo = (item.name + ' ' + item.lastName).split(' | ');

    var getInitials = function (string: string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase();
      }
      return initials;
    };
    let equipoPersonas: IFacepilePersona[] = equipo.map((persona: string) => {
      return {
        personaName: persona,
        imageInitials: getInitials(persona),
      };
    });

    const collaboratorPersona: IPersonaSharedProps = {
      imageInitials: selectedCollaborator ? selectedCollaborator.getInitials() : '',
      text: selectedCollaborator ? selectedCollaborator.fullName() : '',
      secondaryText: selectedCollaboratorCargo ? selectedCollaboratorCargo : '',
      tertiaryText: selectedCollaborator ? selectedCollaborator.getBirthday() : '',
    };

    const collaboratorDetails: IPersonaSharedProps = {
      secondaryText: selectedCollaboratorOrganizationChartNode
          ? selectedCollaboratorOrganizationChartNode.getName()
          : '',
      tertiaryText: selectedCollaborator ? selectedCollaborator.getEmail() : '',
    };

    return (
        <div className="ms-Grid" dir="ltr">
          <Facepile
              personas={equipoPersonas}
              personaSize={PersonaSize.size40}
              maxDisplayablePersonas={1}
              overflowButtonType={1}
              onRenderPersona={(props) => (
                  <>
                    <Persona
                        {...collaboratorPersona}
                        {...props}
                        size={PersonaSize.size120}
                        className="persona-class"
                        onRenderPrimaryText={() => (
                            <div>
                              <b>{selectedCollaborator ? selectedCollaborator.fullName() : null}</b>
                              {/* <span className="persona-collaborator-title"> {' > Nombre'} </span> */}
                            </div>
                        )}
                        onRenderSecondaryText={() => (
                            <div>
                              <b>{positionName ? positionName : null}</b>
                              {/* <span className="persona-collaborator-text"> &nbsp;{' > Cargo'} </span> */}
                            </div>
                        )}
                        onRenderTertiaryText={() => (
                            <div>
                              <>{selectedCollaborator ? selectedCollaborator.getBirthday() : null}</>
                              {/* <span className="persona-collaborator-text"> &nbsp;{' > Fecha de nacimiento'} </span> */}
                            </div>
                        )}
                    ></Persona>
                    <Persona
                        {...collaboratorDetails}
                        {...props}
                        size={PersonaSize.size120}
                        className="persona-detail-class"
                        onRenderPersonaCoin={() => <div className="hidden"></div>}
                        onRenderSecondaryText={() => (
                            <div className="areaYDireccion">
                              {selectedCollaboratorOrganizationChartNode
                                  ? selectedCollaboratorOrganizationChartNode.getName()
                                  : ''}
                            </div>
                        )}
                        onRenderTertiaryText={() => (
                            <div>
                              <b>{selectedCollaborator ? selectedCollaborator.getEmail() : null}</b>
                              {/* <span className="persona-collaborator-text"> &nbsp;{' > Email'} </span> */}
                            </div>
                        )}
                    ></Persona>
                  </>
              )}
          />
        </div>
    );
  };

  const setBirthday = (date: Date | null | undefined) => {
    if (date) {
      setSelectedCollaboratorBirthday(date);

      const yyyy = date.getFullYear();
      let mm: string | number = date.getMonth() + 1; // Months start at 0!
      let dd: string | number = date.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = dd + '/' + mm + '/' + yyyy;
      selectedCollaborator?.setBirthday(formattedToday);
    }
  };

  const getPositionName = () => {
    if (collaboratorPositions && selectedCollaboratorPosition && collaboratorPositions) {
      collaboratorPositions.map((position, i) => {
        if (position.getId() === selectedCollaboratorPosition.getPositionId()) {
          setPositionName(collaboratorPositions[i].getName());
        }
      });
    }
  };

  React.useEffect(() => {
    getPositionName();
  }, [collaboratorPositions, selectedCollaboratorPosition, collaboratorPositions]);

  const eyeIcon: IIconProps = { iconName: 'RedEye' };

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Colaboradores')}
      >
        <div className="ms-Grid  padding-1" dir="ltr">
          <div className="ms-Grid-row project-config-data-row">
            <div className="ms-Grid-col ms-lg12 ms-sm12">
              <div className="ms-Grid-row ">
                <div className="ms-Grid-col ms-sm6 ">
                  <h1 className="persona-collaborator">
                    {selectedCollaborator ? formatEquipo(selectedCollaborator) : null}
                  </h1>
                </div>
              </div>
              {/* <ComboBox
                  defaultSelectedKey={sessionStorage.getItem('language')}
                  label={t('Idioma')}
                  options={[
                    { key: languaje.ES_LANGUAGE_VALUE, text: t('Español') },
                    { key: languaje.EN_LANGUAGE_VALUE, text: t('English') },
                  ]}
                  onChange={(e: any, value: any) => {
                    languaje.saveOnSessionStorageLanguage(value.key);
                    setShowRefreshPageDialog(true);
                  }}
                /> */}
              <div className="ms-Grid-row ">
                <div className="ms-Grid-col ms-sm6 ">
                  <Label style={{ visibility: 'hidden' }}>
                    {showCollabPanel ? t('Ocultar panel') : t('Mostrar panel')}
                  </Label>
                  <DefaultButton
                      iconProps={eyeIcon}
                      text={showCollabPanel ? t('Ocultar panel') : t('Mostrar panel')}
                      onClick={() => {
                        setShowCollabPanel(!showCollabPanel);
                      }}
                  />
                </div>
                {isMyProfile() ? (
                    <div className="ms-Grid-col ms-sm6 ms-textAlignRight ">
                      <div className="ms-Grid-col ms-sm6"></div>
                      <div className="ms-Grid-col ms-sm6">
                        <ComboBox
                            defaultSelectedKey={sessionStorage.getItem('language')}
                            label={t('Idioma')}
                            options={[
                              { key: languaje.ES_LANGUAGE_VALUE, text: t('Español') },
                              { key: languaje.EN_LANGUAGE_VALUE, text: t('English') },
                            ]}
                            onChange={(e: any, value: any) => {
                              languaje.saveOnSessionStorageLanguage(value.key);
                              setShowRefreshPageDialog(true);
                            }}
                        />
                      </div>
                    </div>
                ) : null}
              </div>

              <hr></hr>
              {canEdit ? (
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg2">
                      {canEdit ? (
                          <TextField
                              placeholder={t('Nombre')}
                              defaultValue={selectedCollaborator ? selectedCollaborator.getName() : ''}
                              label={t('Nombre')}
                              readOnly={canEdit ? false : true}
                              onChange={(e: any) => {
                                const val = e.target.value;
                                // setSelectedCollaboratorName(val);
                                selectedCollaborator?.setName(val);
                              }}
                          />
                      ) : null}
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg2">
                      {canEdit ? (
                          <TextField
                              placeholder={t('Apellido')}
                              defaultValue={selectedCollaborator ? selectedCollaborator.getLastName() : ''}
                              label={t('Apellido')}
                              readOnly={canEdit ? false : true}
                              onChange={(e: any) => {
                                const val = e.target.value;
                                selectedCollaborator?.setLastName(val);
                              }}
                          />
                      ) : null}
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg2">
                      {canEdit ? (
                          <DatePicker
                              strings={getLocalizedStrings()}
                              placeholder={t('Fecha de nacimiento')}
                              value={
                                selectedCollaboratorBirthday && selectedCollaboratorBirthday
                                    ? selectedCollaboratorBirthday
                                    : defaultDate
                              }
                              label={t('Fecha de nacimiento')}
                              formatDate={DateCustom.formatDateForDatePicker}
                              onSelectDate={(e) => setBirthday(e)}
                          />
                      ) : null}
                    </div>
                  </div>
              ) : null}
              {canEdit ? (
                  <div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        {dropdownData && canEdit && nodes && lastLevel && (
                            <OrganizationChartDropdown
                                options={nodes}
                                disabled={!props.user.getProfile().isAdminUser()}
                                // label={lastLevel.getName()}
                                label={t('Jefatura')}
                                defaultSelectedKey={selectedCollaboratorChart}
                                onChange={(value: any) => {
                                  // setPositionName(value.text);
                                  selectedCollaboratorPosition?.setNodeId(value.getId());
                                }}
                            />
                            //
                            // <Dropdown
                            //   label="Area"
                            //   onChange={(e, option) => {
                            //     setSelectedCollaboratorOrganizationChartNode(option);
                            //     selectedCollaboratorPosition?.setNodeId(option.key);
                            //   }}
                            //   defaultSelectedKey={
                            //     selectedCollaboratorOrganizationChartNode
                            //       ? selectedCollaboratorOrganizationChartNode.getId()
                            //       : null
                            //   }
                            //   options={mapContextAreasForDropdown()}
                            // />
                        )}
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ">
                        {canEdit && collaboratorPositions && positionName ? (
                            <ComboBox
                                disabled={!props.user.getProfile().isAdminUser()}
                                text={positionName}
                                label={t('Cargo')}
                                onChange={(e: any, value: any) => {
                                  setPositionName(value.text);
                                  selectedCollaboratorPosition?.setPositionId(value.key);
                                }}
                                persistMenu={true}
                                openOnKeyboardFocus={true}
                                options={collaboratorPositions.map((colOption: CompanyPosition) => {
                                  let ret: IComboBoxOption = {
                                    key: colOption.getId(),
                                    text: colOption.getName(),
                                  };
                                  return ret;
                                })}
                            />
                        ) : null}
                      </div>

                      <div className="ms-Grid-row mt-2">
                        <div className="ms-Grid-col ms-sm12 collaborator-toggle">
                          {canEdit ? (
                              <Toggle
                                  defaultChecked={selectedCollaborator?.getActive()}
                                  inlineLabel
                                  onText={t('Activo')}
                                  offText={t('Inactivo')}
                                  disabled={canEdit ? false : true}
                                  onChange={(ev, checked) => {
                                    selectedCollaborator?.setActive(checked ? true : false);
                                  }}
                              />
                          ) : null}
                        </div>
                      </div>
                      <hr></hr>
                    </div>
                  </div>
              ) : null}
              {showCollabPanel && (
                  <CollaboratorPanels
                      system={system}
                      courier={props.courier}
                      changeBlockUI={props.changeBlockUI}
                      from={from}
                      to={to}
                      user={props.user}
                  />
              )}
            </div>
          </div>

          <RefreshPageDialog
              show={showRefreshPageDialog}
              onClose={(conf) => {
                if (conf) {
                  window.location.reload();
                } else {
                  setShowRefreshPageDialog(false);
                }
              }}
          />
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default CollaboratorView;
