import Environment from '../lib/Environment';
import XhrRequestHandler, { StatusResponseJSON } from '../lib/XhrRequestHandler';
import { Collaborator } from './collaborators/Collaborator';
import CollaboratorSystem, { ICollaborator } from './collaborators/CollaboratorSystem';
import { SystemDateParser } from './SystemDateParser';
import { User } from './User';
import { UserProfile } from './UserProfile';

export interface IUserProfile {
  per_id: string;
  per_nombre: string;
  per_nemotecnico: string;
}

export interface IQRurl {
  qrUrl: string;
}

export interface ILoggedUser extends IUser {
  representing: ICollaborator;
  perfil: IUserProfile;
}

export interface IUser {
  us_id: string;
  us_email: string;
  us_nombre: string;
}

class AuthenticationSystem {
  private readonly loginBase = '/loginDVM';
  private loggedUser: User | undefined;

  constructor(
      private requestHandler: XhrRequestHandler,
      private environment: Environment,
      private dateParser: SystemDateParser,
      private collaboratorsSystem: CollaboratorSystem
  ) {
    this.loggedUser = undefined;
  }

  async loggedInUser(): Promise<User> {
    const data = await this.requestHandler.get<ILoggedUser>(
        `${this.loginBase}/isLoggedIn?tz=${encodeURIComponent(this.dateParser.getUserTimezone())}`
    );

    this.loggedUser = this.userFromILoggedUser(data);

    return this.loggedUser;
  }

  async signIn(
      email: string,
      password: string,
      token: string
  ): Promise<StatusResponse<User | IQRurl | null>> {
    const credentials = {
      email,
      password,
      token,
    };
    this.requestHandler.clearCache();
    const response = await this.requestHandler.post<StatusResponseJSON<ILoggedUser | IQRurl | null>>(
        `${this.loginBase}/loginWithCredentials`,
        credentials
    );

    if (response.statusCode === 200) {
      this.loggedUser = this.userFromILoggedUser(response.data as ILoggedUser);
      return new StatusResponse(response.statusCode, this.userFromILoggedUser(response.data as ILoggedUser));
    }

    return new StatusResponse(response.statusCode, response.data as IQRurl | null);
  }

  async validateCode2FA(code) {
    const data = await this.requestHandler.post<ILoggedUser>(`${this.loginBase}/validate-totp-code`, {
      code: code,
    });
    return this.userFromILoggedUser(data);
  }

  corporateSingIn() {
    this.requestHandler.clearCache();
    this.requestHandler.navigateTo(this.environment.corporateSignInUrl());
  }

  signOut(returnTo: string) {
    this.requestHandler.clearCache();
    this.requestHandler.navigateTo(`${this.loginBase}/logout?returnTo=${returnTo}`);
  }

  private userFromILoggedUser({ representing, perfil, ...user }: ILoggedUser) {
    const collaborator = Collaborator.fromICollaborator(this.collaboratorsSystem, representing);
    const profile = UserProfile.fromIUserProfile(perfil);

    return User.fromIUser(collaborator, profile, user);
  }
}

class StatusResponse<T> {
  constructor(private statusCode: number, private data: T) {}

  getStatusCode() {
    return this.statusCode;
  }

  getData() {
    return this.data;
  }

  requireTOTPCode() {
    return this.statusCode === 204;
  }

  shouldShowQRAuth() {
    return this.statusCode === 205;
  }

  isOk() {
    return this.statusCode === 200;
  }
}

export default AuthenticationSystem;
